.absolute-center{
    position: absolute;
    top: 50%;
    left: 50%;
    
    padding: .8em 1.2em;
    color: white;
    background-color: transparent;
    
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

#scroll-beauty::-webkit-scrollbar {
    width: 3px;
    background-color: #f7750e;
    border-radius: 2px;
} 