.main-layout .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.main-layout .trigger:hover {
  color: #1890ff;
}

.main-layout .logo {
  height: 32px;
  margin: 16px;
  /* background: rgba(255, 255, 255, 0.3); */
}


.header-background {
  background: #fff !important;

  /* margin-bottom: 10px */
}

.header-background-submenu {
  background: #fff !important;
  margin-bottom: 10px;
  padding-right: 0px !important;
  position: 'absolute';
  float: 'right';
}

.menu-switch {
  display: 'flex';
  justify-content: 'flex-end';
  margin-bottom: 18px;
  margin-top: 15px;
  margin-right: 10px;
}

.parent-menu .ant-menu-item {
  padding-left: 15px !important
}

.sider-submenu.ant-menu .ant-menu-item {
  margin-right: 0px !important;
}

.sider-submenu.ant-menu .ant-menu-item .ant-menu-item-icon {
  margin-left: 15px;
  /* margin-right: 0px !important; */
}

.sider-submenu.ant-menu.ant-menu-inline-collapsed .ant-menu-item .ant-menu-item-icon {
  margin-left: 15px;
  /* //  margin-right: 0px !important; */
}

.slider-menu {
  /* background: #fff !important ; */
  margin-right: "2px";
  height: 100%;

  /* margin-botto
    m: 10px */
}

.header-menu {
  /* display: flex;
  justify-content: flex-end; */
  /* width: 100%; */
  padding: 0 !important;
  background: white !important;


  .ant-menu-item {
    float: right;
    padding-inline: 8px !important;
    /* height: 48px; */
  }

  .ant-menu-horizontal,
  .ant-menu-item::after,
  .ant-menu-submenu::after {
    border: none !important;
  }
}

/* .ant-menu-item
{
  padding-inline:8px !important
} */

/* .ant-menu-inline-collapsed {
    width: 40px !important;
  }

  .ant-layout-sider-collapsed {
    min-width: 50px !important;
  } */