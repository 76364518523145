.messages--received .message {
	background-color: #ddd;
	border-bottom-left-radius: 5px;
	border-top-left-radius: 5px;
}
.messages--received .message:first-child {
	border-top-left-radius: 15px;
}
.messages--received .message:last-child {
	border-bottom-left-radius: 15px;
}
.messages--sent .message {
	float: right;
	background-color: #1998e6;
	color: #fff;
	border-bottom-right-radius: 5px;
	border-top-right-radius: 5px;
}
.messages--sent .message:first-child {
	border-top-right-radius: 15px;
}
.messages--sent .message:last-child {
	border-bottom-right-radius: 15px;
}
.message {
	display: inline-block;
	margin-bottom: 2px;
	clear: both;
	padding: 7px 13px;
	font-size: 12px;
	border-radius: 15px;
	line-height: 1.4;
}
.message--thumb {
	background-color: transparent !important;
	padding: 0;
	margin-top: 5px;
	margin-bottom: 10px;
	width: 20px;
	height: 20px;
	border-radius: 0px !important;
}

.ant-upload.ant-upload-select-picture-card{
	width: 100%;
	height: 100%;
	border: none;	
}
  
.ant-upload.ant-upload-select-picture-card > .ant-upload{
	display: block;
}

.ant-upload-picture-card-wrapper{
	width: auto;
}
.carousel.carousel-slider{
	overflow: visible !important;
}