.stamp {
    position: relative;
    height: 155px;
    overflow: hidden;
    /* -webkit-filter: drop-shadow(1px 1px 1px black); */
    filter: drop-shadow(0px 0px 2px #e5e5e5);
  }
  
  .stamp::before {
    position: absolute;
    left: -5px;
    display: block;
    content: "";
    background: radial-gradient(circle, #e5e5e5 7px, transparent 8px);
    background-size: 20px 22px;
    background-position: -27px -18px;
    width: 14px;
    height: 800px;
    z-index: 1000;
    border: none;
  }
  
  .stamp::after {
    transform: rotate(180deg);
    position: absolute;
    right:-5px;
    bottom: -8px;
    display: block;
    content: "";
    background: radial-gradient(circle, #e5e5e5 7px, transparent 8px);
    background-size: 20px 22px;
    background-position: -27px -32px;
    width: 14px;
    height: 800px;
    z-index: 1000;
    border: none;
  }
  
  body::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  
  .name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 500;
    line-height: 1;
    width: 86%;
    height: 34px;
  }
  
  .exchange {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      font-weight: 500;
      width: 86%;
      height: 34px;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
  }
  
  .header {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 500;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
  }
  
  .description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 86%;
    height: 36px;
    word-break: break-word;
    font-size: 13px;
    font-weight: 400;
    margin-top: 10px;
    color: #cdcdcd;
    line-height: 18px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .cubeloyalty_wraper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .cubeloyalty_content {
    flex: 1;
    overflow-y: scroll;
    padding: 10px;
  }
  
  @media only screen and (min-width: 768px) {
    .cubeloyalty_b_lr {
      border-right: 1px solid rgb(229, 229, 229);
      border-left: 1px solid rgb(229, 229, 229);
    }
  }
  