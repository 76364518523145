.center-layout{
    margin: auto;
    justify-content: center;
    text-align: center;
    background: black;
}

.background-left{
    height: "100vh";
    background-color: "#E4EBF7"
}