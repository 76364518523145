
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wdth,wght@125,900&display=swap');


.cardW-all{
  /*width: 100%; text-align:center; margin: auto;*/
  /*text-align:center;*/
  background-image: linear-gradient(315deg, #f6f0c4 0%, #d99ec9 74%);
  font-family: 'Encode Sans', sans-serif;
  width: 100%;
  height: 100%;
  /*background: #F6ECF0;*/
  border-radius: 10px;
  box-shadow: 0 0 15px 8px #d0d0d0;
  box-sizing: border-box;
  padding: 5px 25px 5px 25px;

}
.cardW-all-test{
  /*width: 100%; text-align:center; margin: auto;*/
  /*text-align:center;*/
  margin: 0 auto;
  background-image: linear-gradient(315deg, #f6f0c4 0%, #d99ec9 74%);
  font-family: 'Encode Sans', sans-serif;
  width: 65%;
  height: 100%;
  /*background: #F6ECF0;*/
  border-radius: 10px;
  box-shadow: 0 0 15px 8px #d0d0d0;
  box-sizing: border-box;
  padding: 5px 25px 5px 25px;
  display: flex;
  flex-display: row;
  justify-content: space-around;
  padding: 100px 0 100px 0;
}

.cardW-header{
  margin-top: 10px;
  font-size: 24px;
  font-weight: bolder;
  /*color: #616161;*/
  color: white;
  /*color: rgba(0,0,0,.9);*/
  text-shadow: grey 2px 2px 0;
  font-family: 'Encode Sans', sans-serif;

}

.cardW-turn{
  text-shadow: grey 2px 2px 0;
  /*color: black;*/
  color: white;

  font-size: 15px;
  margin-top: 10px;

  font-family: 'Encode Sans', sans-serif;

}

.wheel-container{
  display: flex;
  margin-top: 50px;
  justify-content: center;
  width: 100%;
  height: 55vh;
}
.Wdesc{
  font-family: 'Encode Sans', sans-serif;
  /*margin-top: ;*/
  font-size:13px;
  color:white;
  text-shadow: 1px 1px 0 #1D4E89, 1px -1px 0 #1D4E89, -1px 1px 0 #1D4E89, -1px -1px 0 #1D4E89, 1px 0px 0 #1D4E89, 0px 1px 0 #1D4E89, -1px 0px 0 #1D4E89, 0px -1px 0 #1D4E89;

}
.Wtime{
  text-align:center;
  font-family: 'Encode Sans', sans-serif;
  margin-top: 75px;
  font-size: 12px;
  text-shadow: white 1px 1px 0;
  color: #1D4E89;
}





/* Sets the background image for the wheel */
.wheel
{
  cursor:pointer;
  position:relative;
  border-radius: 50%;
  width: 465px;
  height: 465px;
  background-color: #1D4E89;

}
.wheel-child
{
  position: absolute;
  top: 50%;
  left: 50%;
  border: 15px solid white;
  background-color: #1D4E89;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  transform: translate(-50%, -50%);
}
.wheel-child-arrow
{
  position: absolute;
  top:-25px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 30px solid white;
  /*border: 2px solid blue;*/
  transform: translate(-50%, -50%);

}
.wheel-child-arrow1
{
  position: relative;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 25px solid #1D4E89;
  /*border: 2px solid blue;*/
  transform: translate(-50%, -50%);

}


