.upload-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

* {
  -ms-overflow-style: none;
}
/* ::-webkit-scrollbar {
    display: none;
} */

#phone-frame {
  background-image: url("../../../assets/images/phone-frame.png");
  background-repeat: no-repeat;
  background-size: 370px, 600px;
  margin-left: -10px;
  width: 400px;
  height: 800px;
  position: static;
}

div.sub-top {
  width: 110px;
  height: 95px;
  margin: 0 auto;
}

.Modal .ant-modal-content {
  width: 100vw;
}
.footer .ant-btn {
  margin-bottom: 20px;
}
div.ql-editor {
  min-height: 500px;
}

.display-sms-fpt {
  background: #F0F2F5;
  min-height: 50vh;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.form-sms-fpt > .ant-form-horizontal.ant-form-item-control{
  flex:none !important;
}

.block-center{
  min-height: 88px;
  height: auto;
  display: flex;
  text-align: start;
  justify-content: center;
  align-items: center;
}
