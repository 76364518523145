.upload-space {
    margin-top: 20px;
    padding: 0px 16px;
}

.upload-card {
    height: auto;
    font-family: 'Roboto', sans-ser;
    border-radius: 20px !important;
}

.btn-zalo-oa {
    color: #91D5FF;
    width: 65px;
    height: 22px;
    border-radius: 2px;
    padding: 1px 8px 1px 8px;
    Gap: 3;
    font-size: 10px;
    background-color: #E6F7FF;
    border: 1px solid #91D5FF;
}

.oa-id {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-style: normal;
}

.container-upload {
    margin: auto;
    width: 78%;
    height: auto;
    padding-top: 15px;
}

.container-upload-header {
    margin-bottom: 8px;
    margin-left: 11.5px
}

.container-upload-body {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
}

.upload-image {
    margin-left: auto;
    margin-right: auto;
}

/* .ant-card-body{
    height: 100% !important;
    padding: 16px !important;
} */

.content-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.btn-save {
    float: right !important;
    width: 95px;
    height: 32px;
    background-color: #FFFFFF;
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.85)
}

.btn-save:hover {
    border: 1px solid red;
}

.container-center {
    margin: auto;
    width: 50%;
    /* background-color: red; */
    height: auto;
}