.main_image {
    .ant-upload {
        width: 250px !important;
        height: 250px !important;
    }
}

.image {
    .ant-upload {
        width: 120px !important;
        height: 120px !important;
    }
}

.banner_image {
    .ant-upload-wrapper {
        display: flex !important;
        justify-content: center !important;
    }

    .ant-form-item-explain-error {
        text-align: center;
    }
}

.category_image {
    .ant-form-item-control-input-content {
        display: flex;
        gap: 8px
    }

    .ant-upload-wrapper {
        width: 102px !important;
    }
}