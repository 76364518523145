.payment-modal {
    .ant-modal-content {
        padding-left: 0!important;
        padding-right: 0 !important;
    }

    .ant-modal-header, .ant-modal-footer {
        padding-left: 24px;
        padding-right: 24px
    }  

    .ant-modal{
        max-width: 848px;
    }
}
  

    





.card-bank .ant-card-body{
    padding: 0 !important
}