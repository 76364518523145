
.content_zalov2{
    padding:16px;
}
.ZaloApp_Card_tabBarExtraContent .ZaloApp_Card_Label{
    font-weight: 400;
}
.ListTemplate_row{
    padding-bottom: 8px;
    padding-right: 8px;
    /* gap: 10px; */
}
.ListTemplate_button_header>div>span{
    align-items: center;
    padding: 4px 15px
}

.content_zalov2_ListTemplate_Modal{
    margin-left: -24px !important;
    margin-right: -24px !important;
    background-color: #f2f4f8;
}
.content_zalov2_ListTemplate_Modal_iframe{
    display: block;
    width: 100%;
    height: 456px;
}
.page-content>.bl-preview-template>.heading-preview{
    display: none !important;
}
.InformationAccount_row{
    padding: 0px 16px;
    gap: 16px;
}
.InformationAccount_col_vertical{
    border-left: 1px solid #CAC4D0;
    background-color: #CAC4D0;
    width: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;

}
.SendingHistory_row{
    padding: 8px;
}
.SendingHistory_select{
    width: 100%;
}
.SendingHistory_select>div{
    border-radius: 2px !important;
}
.SendingHistory_Popover{
    width: 400px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
    /* white-space: pre-wrap; let the text wrap preserving spaces */
}    
.SendingHistory__Popover-text{
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
width: 400px;
}

.SendingHistory__Popover-ReadOrHide{
color: blue;
cursor: pointer;
}