.label
{
    font-weight: bold;
}

.customer-upload div.ant-upload
{
    height: 28vh;
    width: 23.5vw;
}

.customer-upload div.ant-upload-list-picture-card-container
{
    height: 28vh;
    width: 100%;
}

.customer-upload svg
{
    height: 1.3em;
    width: 1.3em;
}

.customer-upload span.anticon-delete
{
    margin-left: 50px;
}