.coverimage-upload div.ant-upload
{
    height: 20vh;
    width: 8vw;
}

.coverimage-upload div.ant-upload-list-picture-card-container
{
    height: 28vh;
    width: 100%;
}

.coverimage-upload svg
{
    height: 1.3em;
    width: 1.3em;
}

@media screen and (max-width: 1400px) {
    .coverimage-upload div.ant-upload
    {
        height: 12vh;
        width: 12vw;
    } 
}
