.lk_btn {
    background: rgba(44 4 4, .5);

    border-radius: 10px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    width: 42px;
    height: 42px;
}

.lk_btn img {
    width: 100%;
    height: auto;
}

.lk_btnMoreTurn {
    background: linear-gradient(180deg, #FB7E0C 0%, #E75117 100%);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -50px;
    color: white;
    font-weight: bold;
    height: 40px;
}

.lk_btn_footer {
    background: rgba(44 4 4, .5);

    border-radius: 10px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    width: 61px;
    height: 61px;
}

.game_image {
    .ant-upload.ant-upload-select {
        width: 400px !important;
        height: 300px !important;
    }

    .ant-upload-list-item-container {
        width: 400px !important;
        height: 300px !important;
        /* text-align: center; */
    }
}

.game_background {
    .ant-upload-list {
        display: flex;
        justify-content: center;
    }

    .ant-upload.ant-upload-select {
        width: 320px !important;
        height: 700px !important;
        text-align: center;
    }

    .ant-upload-list-item-container {
        width: 320px !important;
        height: 700px !important;
        text-align: center;
    }
}

.game_config_table {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
}