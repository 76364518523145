.coverimage-upload div.ant-upload {
  height: 200px;
  width: 100%;
}

.coverimage-upload div.ant-upload-list-picture-card-container {
  height: 28vh;
  width: 100%;
}

.coverimage-upload svg {
  height: 1.3em;
  width: 1.3em;
}
